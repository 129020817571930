import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

const firebaseApp = firebase.initializeApp(firebaseConfig);

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6Lept7oqAAAAAOz8Y2CVqj1IfJrwuMevAoNqOFjF"),
  isTokenAutoRefreshEnabled: true,
});

export const storage = firebaseApp.storage().ref();
export const firebaseAppAuth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
const functions = firebaseApp.functions();

export const storeData = async (collection, id, data) => {
  return firestore.collection(collection).doc(id).set(data);
};

export const deleteData = (collection, id) => {
  return firestore.collection(collection).doc(id).delete();
};

export const getDocument = (collection, id) => {
  return firestore
    .collection(collection)
    .doc(id)
    .get()
    .then((snapshot) => {
      return snapshot.data();
    });
};

export const getData = (collection) => {
  return firestore
    .collection(collection)
    .get()
    .then((snapshot) => {
      const rawData = snapshot.docs.map((doc) => {
        return doc.data();
      });
      return rawData;
    })
    .catch((err) => console.log(err));
};

export const getSortedData = (collection) => {
  return firestore
    .collection(collection)
    .get()
    .then((snapshot) => {
      const rawData = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
          order: doc.data().order ? parseInt(doc.data().order) : null,
        };
      });
      return quikSort(rawData);
    })
    .catch((err) => console.log(err));
};

const quikSort = (listToSort) => {
  let less = [];
  let equal = [];
  let greater = [];

  if (!elementsHaveOrder(listToSort)) {
    return listToSort;
  }

  if (listToSort.length > 1) {
    let pivot = listToSort[0];

    listToSort.forEach((element) => {
      if (element.order < pivot.order) {
        greater.push(element);
      } else if (element.order === pivot.order) {
        equal.push(element);
      } else {
        less.push(element);
      }
    });

    return quikSort(less).concat(equal, quikSort(greater));
  } else {
    return listToSort;
  }
};

const elementsHaveOrder = (arrToCheck) => {
  let check = true;
  arrToCheck.forEach((element) => {
    if (!element.order) {
      check = false;
    }
  });
  return check;
};

export const callCloudFunctionWithAppCheck = (functionToCall, data) => {
  return functions.httpsCallable(functionToCall)(data);
};
